<template>
  <div>
    <div class="top">
      <div class="tiles1">四川南十字数据</div>
      <div class="about">
        <a href="/">主页</a> &nbsp; &nbsp; &nbsp; &nbsp;
        <a href="http://www.sccrux.com" target="_blank">关于</a>
      </div>
    </div>
    <div class="centent">
      <div class="cententleft">
        <div class="leftcontent">
          <div class="blogtitle">
            <div>
              <unicon name="database" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('数据库运维')">&nbsp; 数据库运维</div>
          </div>
          <div class="blogtitle">
            <div>
              <unicon name="analytics" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('数据库调优')">&nbsp; 数据库调优</div>
          </div>

          <div class="blogtitle">
            <div>
              <unicon name="panel-add" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('数据库健康检查')">
              &nbsp; 数据库健康检查
            </div>
          </div>
          <div class="blogtitle">
            <div>
              <unicon name="arrow-up" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('数据库迁移和升级')">
              &nbsp; 数据库迁移和升级
            </div>
          </div>
          <div class="blogtitle">
            <div>
              <unicon name="analysis" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('Power BI')">&nbsp; Power BI</div>
          </div>
          <div class="blogtitle">
            <div>
              <unicon name="code-branch" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('Power BI服务器开发')">
              &nbsp; Power BI服务器开发
            </div>
          </div>
          <div class="blogtitle">
            <div>
              <unicon name="brackets-curly" width="50px" height="50px" fill="#F1664E"></unicon>
            </div>
            <div @click="ClickType('Power Query开发')">
              &nbsp; Power Query开发
            </div>
          </div>
        </div>
      </div>
      <div class="cententright">
        <div class="rightcontent">
          <div class="row" v-for="(item, index) in blogContent" :key="index" @click="currentClick(item.path)">
            <div class="rowtitle">
              {{ item.name }}&nbsp;&nbsp;&nbsp;&nbsp;【{{ item.type }}】
            </div>
            <div class="rowcontent">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "animate.css";
  //import allblog from "@/articles/allblog.json";

  let imgurl = require("@/assets/static.jpg");
  let sql01 = require("@/assets/SQL01.jpg");
  let logourl = require("@/assets/logo.png");
  export default {
    metaInfo: {
      title: "南十字数据——我们很强！",
      meta: [
        {
          name: "description",
          content:
            "南十字数据，SQL Server规划、部署、运维、调优专家。Power BI 服务器定义身份验证开发，自定义门户，Power BI报表服务器SSO，Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发",
        },
        {
          name: "keywords",
          content:
            "南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发",
        },
        {
          name: "anthor",
          content:
            "ADDRESS: Chengdu , China Gaoxin Dist, Tianfu Five Road Jingrong International Piazza Building 4A, 12F Mail: maxcloud@outlook.com Tel:86-28-18628037379",
        },
        {
          name: "robots",
          content:
            "南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发",
        },
      ],
    },

    data() {
      return {
        imgurl: imgurl,
        logourl: logourl,
        sql01: sql01,
        allblogContents: [],
        blogContent: [],
      };
    },
    mounted() { },
    created() {
      this.$http.get("articles/allblog.json").then((res) => {
        //console.log(res.data);
        var appData = res.data;
        //console.log(appData);
        this.blogContent = appData.blogContent;
        this.allblogContents = this.blogContent;
      });
    },

    methods: {
      ClickType(type) {
        console.log(type);
        var blogs = [];
        this.allblogContents.forEach((x) => {
          if (x.type == type) {
            blogs.push({
              name: x.name,
              type: x.type,
              content: x.content,
              path: x.path,
            });
          }
        });
        console.log(blogs);
        this.blogContent = blogs;
      },
      currentClick(url) {
        this.$router.push({ path: "/blogContent", query: { path: url } });
      },
    },
  };
</script>

<style>
  body {
    margin: 0;
    padding: 0;
    z-index: -1;
  }

  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: none;
  }
</style>
<style lang="less" scoped>
  .top {
    height: 100px;
    width: 98%;
    margin-left: 1%;
    // background: azure;
    // background-image: url("../assets/static.jpg");
    // background: transparent url("assets/static.jpg") no-repeat;
    //filter: brightness(50%);
    //z-index: -1;
    //opacity: 0.9;
    display: flex;
    background: aliceblue;
  }

  .centent {
    display: flex;
    width: 98%;
    margin-left: 1%;
    margin-top: 4px;
    margin-bottom: 4px;
    //transform: rotateX();
    //background-color: transparent;
  }

  .cententleft {
    width: 20%;
    // height: 600px;
    //margin-left: 1px;
    background-color: transparent;

    //background: rgb(236, 66, 194);
  }

  .row {
    text-align: left;
    //height: 200px;
    background-color: white;

    //w background-color: brown;
  }

  .rowtitle {
    width: 100%;
    margin-left: 2px;
    line-height: 50px;
    font-size: 24px;
    height: 50px;
    background-color: white;
    margin-top: 2px;
    font-weight: bold;
    // border: 1px solid rgb(185, 162, 162);
  }

  .rowcontent {
    text-align: left;
    height: 150px;
    width: 100%;
    margin-left: 2px;
    background-color: white;
    border-top: azure 1px;
    font-size: 18px;
    border-top: 1px solid rgb(83, 81, 81);
    color: rgb(106, 106, 107);
  }

  .cententright {
    width: 80%;
    background-color: transparent;
    //height: 100%;
    // background: cornflowerblue;
  }

  .rightcontent {
    width: 100%;
    // background: cornsilk;
    border: 1px solid rgb(185, 162, 162);
    margin-left: 2px;
    height: 100%;
    cursor: pointer;
    // background-color: white;
    // opacity: 0.4;
    // margin: 2px 2px 2px 2px;
  }

  .leftcontent {
    width: 100%;
    // background: cornsilk;
    border: 1px solid rgb(185, 162, 162);
    height: 100%;
    border-color: darkgray;
    //background-color: rgb(248, 248, 248);
    // background: rgba(248, 248, 248, 0.8);
    //opacity: 0.4;
    position: relative;
    // filter: Alpha(opacity=50);
    //margin: 2px 2px 2px 2px;
  }

  .blogtitle {
    display: flex;
    text-align: left;
    color: black;
    font-weight: bold;
    // position: relative;
    height: 50px;
    font-size: 22px;
    line-height: 50px;
    margin: 2px 2px 2px 2px;
    width: 98%;
    background-color: white;
    cursor: pointer;
  }

  .tile {
    //border-left: 1px solid #969393;
    font-size: 30px;
    font-weight: 600;
    // float: left;
    line-height: 100px;
    height: 100px;
    text-align: left;

    // background-color: #363535;
  }

  .tiles {
    height: 100px;
    display: flex;
    background: aliceblue;
  }

  .tiles1 {
    font-size: 40px;
    height: 100px;
    font-weight: bold;
    line-height: 100px;
    text-align: left;
    margin-left: 10px;
    background: aliceblue;
    width: 40%;
    display: flex;
  }

  .about {
    width: 30%;
    margin-left: 30%;
    left: 0px;
    background: aliceblue;
    font-size: 14px;
    font-weight: bold;
    line-height: 100px;
    text-decoration: none;
  }

  .titleConent {
    border-left: 1px solid #969393;
    border-right: 1px solid #969393;
    line-height: 28px;
    font-size: 14px;
    //float: right;
    width: 80%;
    //background-color: aqua;
    text-align: left;
  }

  .tileright {
    //width: 80%;
    padding: 20px;
    margin-right: 10%;
    //background-color: rgb(148, 104, 1);
    text-align: right;
    line-height: 30px;
    height: 30px;
    display: flex;
    margin: auto;
  }
</style>